import { log, api } from ":mods";
import { ENDPOINTS } from "../const";

export async function logout() {
  return api
    .getAuth(ENDPOINTS.get_logout)
    .then(async (res) => {
      // console.log("login res :: ", res);
      return Promise.resolve();
    })
    .catch((err) => {
      log.dev.info("logout error :: ", err);
      return Promise.reject(err);
    });
}
